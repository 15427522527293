export function init(view) {
	view.attachEvent("onCommand", (action) => {
		if (action.id == "show" || action.id == "hide"){
			const sel = view._table.getSelectArea();
			if (sel)
				process(action, webix.copy(sel.start), webix.copy(sel.end), view);
		}
	});
	reset(view);

	view.attachEvent("onUndo", (action, row, column, value, direction) => {
		if ((action.id == "hide" || action.id == "show") && (action.group == "column" || action.group == "row")){
			let type = action.id;
			if (!direction)
				type = type == "hide" ? "show" : "hide";
			const obj = (row || column);
			process({ id: type, group: action.group }, obj.start, obj.end, view, true);
		}
	});

	view.attachEvent("onHardReset", () => reset(view));

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			_updatePosition(view, p.name,p.inc,p.data, p.start);
	});
}

export function reset(view, mode) {
	if(mode) view["_hidden_"+mode+"_hash"] = {};
	else{
		view._hidden_cols_hash = {};
		view._hidden_rows_hash = {};
		view.filterSpreadSheet();
	}
}

function getIds(id){
	return webix.isArray(id) ? id : [id, id];
}

export function isColumnVisible(view, column){
	return !view._hidden_cols_hash[column];
}

export function setState(view, indexes, state, type){
	// hide
	if(!indexes){
		const sel = view.getSelectedId(true);
		const action = {
			id: state === false ? "show" : "hide",
			group: type
		};

		if(sel.length)
			view.callEvent("onCommand", [action, sel[0], sel[sel.length-1]]);
	}
	else {
		const ids = getIds(indexes);
		const hidden = type == "row" ? view._hidden_rows_hash : view._hidden_cols_hash;

		if (!hidden[ids[0]] && (webix.isUndefined(state) || state)){
			(type == "row" ? _hideRow : _hideColumn)({id: "hide", group: type}, {[type]: ids[0]}, {[type]: ids[1]}, view);
		}
		// show
		else if (hidden[ids[0]] && (webix.isUndefined(state) || !state)) {
			const inc = ids[0] > 1 ? 1 : -1;
			(type == "row" ? _showRow : _showColumn)({id: "hide", group: type}, {[type]: ids[0] + inc}, {[type]: ids[1] + inc}, view);
		}
	}
}

export function isRowVisible(view, row){
	return !view._hidden_rows_hash[row];
}

export function serialize(view, data){
	let hrows  = []; let hcols = [];
	for(let r in view._hidden_rows_hash) hrows.push(r);
	for(let c in view._hidden_cols_hash) hcols.push(c);

	if(hrows.length || hcols.length)	
		data.table.hidden = {};
	if(hrows.length)
		data.table.hidden.row = hrows;
	if(hcols.length)
		data.table.hidden.column = hcols;
}

export function load(view, data){
	reset(view);
	
	if(!webix.isUndefined(data.table) && !webix.isUndefined(data.table.hidden)){
		const hidden = data.table.hidden;

		if(hidden.row && hidden.row.length){
			for(let r = 0; r < hidden.row.length; r++)
				_hideRow({id:"hide", group:"row"}, {row:hidden.row[r]}, {row:hidden.row[r]}, view, true);
		}

		if(hidden.column && hidden.column.length){
			for(let c = 0; c < hidden.column.length; c++)
				_hideColumn({id:"hide", group:"column"}, {column:hidden.column[c]}, {column:hidden.column[c]}, view, true);
		}
	}
}

function process(action) {
	if (action.group == "column"){
		if (action.id == "show")
			_showColumn.apply(this, arguments);
		else if (action.id == "hide")
			_hideColumn.apply(this, arguments);
	} else if (action.group == "row") {
		if (action.id == "show")
			_showRow.apply(this, arguments);
		else if (action.id == "hide")
			_hideRow.apply(this, arguments);
	}
}

function _showColumn(action, start, end, view, silent) {
	let i = end.column;
	const grid = view.$$("cells");

	while (i >= start.column) {
		let id = i;
		if (!silent)
			id = view._hidden_cols_hash[i*1-1] || view._hidden_cols_hash[i*1+1] || false;

		if (id !== false) {
			delete view._hidden_cols_hash[id];
			let cell = grid.getColumnConfig(id - 1 || "rowId").header[0];
			cell.css = cell.css.replace("webix_ssheet_hide_column", "");
			grid.showColumn(id);
			start.column = start.column < id ? start.column : id;
			end.column = end.column > id ? end.column : id;

			if (!silent)
				view.callEvent("onColumnOperation", [action, { column: id }, { column:id }, null]);
		}
		i--;
	}

	const split = view._frozenColumns;
	if(split && split >= start.column && split <= end.column){
		view.freezeColumns(0); // remove freeze (split is equal with current frozen col)
		view.freezeColumns(split); // if col was freezed on hidden column - move it
	}
}

function _showRow(action, start, end, view, silent) {
	let i = end.row;
	const grid = view.$$("cells");

	const split = view._frozenRows;
	if(split){
		//Frozen rows ignore filtering applied to the datatable
		view.freezeRows(0);
	}

	while (i >= start.row) {
		let id = i;
		if (!silent)
			id = view._hidden_rows_hash[i*1-1] || view._hidden_rows_hash[i*1+1] || false;

		if (id !== false) {
			delete view._hidden_rows_hash[id];
			if (id - 1 === 0) {
				let cell = grid.getColumnConfig("rowId").header[0];
				cell.css = cell.css.replace("webix_ssheet_hide_row", "");
				grid.refreshColumns();
			} else {
				grid.removeCellCss(id - 1, "rowId", "webix_ssheet_hide_row");
			}
			if (!silent)
				view.callEvent("onRowOperation", [action, { row: id }, { row: id }, null]);
		}
		i--;
	}

	view.filterSpreadSheet();

	if(split)
		view.freezeRows(split);
}

function _hideColumn(action, start, end, view, silent) {
	const grid = view.$$("cells");
	let i = end.column;
	view.$handleSelection = null;
	grid.unselect();

	if (end.column<1 || start.column<1){
		delete view._hidden_cols_hash[start.column];
		return;
	}
	while (i >= start.column) {
		if(grid.getColumnIndex(i) != -1){
			view._hidden_cols_hash[i] = i;

			let cell = grid.getColumnConfig(i - 1 || "rowId").header[0];
			cell.css = (cell.css || "") + " webix_ssheet_hide_column";

			grid.hideColumn(i);
		}
		i--;
	}

	if (!silent)
		view.callEvent("onColumnOperation", [action, start, end, null]);
}

function _hideRow(action, start, end, view, silent) {
	if (end.row<1 || start.row<1){
		delete view._hidden_rows_hash[start.row];
		return;
	}

	const split = view._frozenRows;
	if(split){
		//Frozen rows ignore filtering applied to the datatable
		view.freezeRows(0);
	}

	const grid = view.$$("cells");
	let i = end.row;

	while (i >= start.row) {
		view._hidden_rows_hash[i] = i;

		if (i - 1 === 0) {
			let cell = grid.getColumnConfig("rowId").header[0];
			cell.css = (cell.css || "") + " webix_ssheet_hide_row";
			grid.refreshColumns();
		}
		else
			grid.addCellCss(i - 1, "rowId", "webix_ssheet_hide_row");

		i--;
	}

	view.filterSpreadSheet();

	if(split)
		view.freezeRows(split);

	if (!silent)
		view.callEvent("onRowOperation", [action, start, end, null]);
}

function _updatePosition(view, name, inc, data, start){
	if(data.table && data.table.hidden && data.table.hidden[name] && data.table.hidden[name].length){
		if(name ==="column") reset(view, "cols");
		if(name === "row") reset(view, "rows");

		let s = start[name], e = s + inc;
		if(s > e) [s, e] = [e, s];

		for(let i = s; i < e; i++){
			for(let h = 0; h < data.table.hidden[name].length; h++){
				if(data.table.hidden[name][h] >= i)
					data.table.hidden[name][h] = data.table.hidden[name][h]*1+(inc>0 ? 1: -1);
			}
		}
	}
}