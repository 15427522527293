import {addStyle} from "./styles";
import {getJsDateFromExcel} from "../math/methods/helpers";
import group from "./undo_group";

export function init(view){
	view.attachEvent("onDataParse", (data) => load(view, data));
	view.attachEvent("onDataSerialize", (data, config) => serialize(view, data, config));
}

export let formatHelpers = {};

let formats;
let formatsCount;
let formatSources;
let formatCache;

export function setDefaultFormats(){
	const formatsLocale = webix.i18n.spreadsheet.formats;
	formats = {};
	formatsCount = 0;
	formatSources = {};
	formatCache = {};
	formatHelpers = {
		price : {
			getFormat : function(value, extra){
				extra.css = "webix_ssheet_format_price";
				return webix.i18n.priceFormat(value);
			},
			values:webix.extend({
				zeros: webix.i18n.priceSettings.decimalSize, 
				symbol: webix.i18n.priceFormat(""),
				separator: 1, negative:1, type: "price"
			}, getDelimiters("price"))
		},
		"int" : {
			getFormat : function(value, extra){
				extra.css = "webix_ssheet_format_int";
				return webix.i18n.numberFormat(value);
			},
			values:webix.extend({
				zeros: webix.i18n.decimalSize,
				separator: 1, negative:1, type: "int",
			}, getDelimiters("int"))
		},
		percent : {
			getFormat : function(value, extra){
				extra.css = "webix_ssheet_format_percent";
				return Math.round(value*100)+"%";
			},
			values:webix.extend({
				zeros: 0, separator: 0, negative:1, type: "percent",
			}, getDelimiters("percent"))
		},
		date : {
			getFormat : function(value, extra){
				extra.css = "webix_ssheet_format_date";
				return format2code(formatsLocale.dateFormat, getDelimiters("date"))(value);
			},
			values: { type:"date", date:formatsLocale.dateFormat }
		},
		string : {
			getFormat : function(value, extra){
				extra.css = "webix_ssheet_format_text";
				return value;
			},
			values: { type:"string" }
		}
	};

	for(let i in formatHelpers){
		formatSources[i] = serializeFormat(formToValues(i, formatHelpers[i].values));
		formatCache[formatSources[i]] = i;
	}
}

export function getDelimiters(type){
	if(type == "price")
		return { groupSign:webix.i18n.priceSettings.groupDelimiter, decimalSign:webix.i18n.priceSettings.decimalDelimiter };
	else 
		return { groupSign:webix.i18n.groupDelimiter, decimalSign:webix.i18n.decimalDelimiter };
}

function serialize(view, data){
	const formats = [];
	const defaultFormats = ["percent", "int", "price", "date", "string"];

	for(let i in formatSources){
		if(defaultFormats.indexOf(i) == -1) //exclude default formats
			formats.push([i,formatSources[i]]);
	}
	data.formats = formats;
}

function load(view,data){
	var i,
		formats = data.formats;

	if(formats)
		for(i = 0; i < formats.length; i++)
			createFormatName(formats[i][1],formats[i][0]);
}

export function getFormat(name){
	return formatHelpers[name] ? formatHelpers[name].getFormat : formatHelpers[name];
}

export function getFormatSource(name, toexcel){
	if(toexcel){
		let parsed = parseFormat({values: formatHelpers[name].values, format: formatSources[name]});
		return serializeFormat(parsed, true);
	}
	else
		return formatSources[name];
}

function createFormatName(str, values){
	if (formatCache[str]) return formatCache[str];

	let name = typeof(values) == "object" ? "fmt"+(formatsCount) : values;
	formatsCount++;

	formats[name] = str;

	values = webix.extend(
		typeof(values) == "object" ? values : { format:str, type:"custom" }, 
		getDelimiters("custom")
	);

	formatHelpers[name] = {
		getFormat:format2code(str, {decimalSign: values.decimalSign, groupSign: values.groupSign}),
		values: values
	};

	formatSources[name] = str;
	formatCache[str] = name;

	return name;
}

export function addFormat(view, row, column, format, type){
	if(!type)
		type = {format:format, type: "custom"};
	const old = view.getStyle(row, column);
	const nev = addStyle(view, { format:createFormatName(format, type) }, old);
	view.setStyle(row, column, nev);
}

export function removeFormat(view, row, column){
	const old = view.getStyle(row, column);
	const nev = addStyle(view, { format:"" }, old);
	view.setStyle(row, column, nev);
}

function splitFormat(str){
	var conditional = str.match(/.*\[[><=].*/g);
	var parts = str.split(";");
	if (!conditional){
		if (parts.length > 1){
			parts[0] = (parts.length > 2 ? "[>0]" : "[>=0]")+parts[0];
			parts[1] = "[<0]"+parts[1];
			if (parts[2])
				parts[2] = "[=0]"+parts[2];
		}
	}
	return parts;
}

export function format2code(str, delimiters){
	const errorMessage = webix.i18n.spreadsheet.table["format-error"];
	let parts = splitFormat(str);
	let code = ["var spaces = \"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\";"];
	for (var i=0; i<parts.length; i++){
		var separated = separateFmt(parts[i]);
		code.push(genCode(separated.condition, separated.color.toLowerCase(), separated.fmt, i, parts.length > 0, delimiters));
	}

	return new Function("val","extra", `try{ ${code.join("\n")} return val; } catch(err) { return "${errorMessage}"; }`);
}

function genCode(check, color, line, ind, isAbs, delimiters){
	var str = "";
	if (check){
		if (!ind) str+="if"; else str+="else if";
		if (check[0] === "=") check = "="+check;
		str+="(val"+check+"){";
		if (isAbs) str+="val = Math.abs(val);";
	}
	if (color)
		str+="extra.css = \"webix_ssheet_format_"+color+"\";";

	str += genFormatCode(line, delimiters);

	return str+(check?"}":"");
}

const dateTypes = {
	"yy": "%y",
	"yyyy": "%Y",
	"m": "%n",
	"mm": "%m",
	"mmm": "%M",
	"mmmm": "%F",
	"mmmmm":"%F", //not supported by webix
	"d": "%j",
	"dd": "%d",
	"ddd": "%D",
	"dddd": "%l"
};

const timeTypes = {
	"h": "%G",
	"hh": "%H",
	"m": "%i", //not supported by webix
	"mm": "%i",
	"s": "%s", //not supported by webix
	"ss": "%s",
	"AM/PM": "%A"
};

const dateOrder = Object.keys(dateTypes).sort((a, b) => b.length - a.length);
const timeOrder = Object.keys(timeTypes).sort((a, b) => b.length - a.length);

function excelDateToWebix(text, lastDateType){
	const typesOrder = [
		{name:"date", order:dateOrder, types:dateTypes}, 
		{name:"time", order:timeOrder, types:timeTypes}
	];

	// "m"/"mm" format can be either date (%n/%m) or time (%i), so check last date type
	const basedOnLast = /^m{1,2}(?!m)/.test(text);
	if(basedOnLast && lastDateType != "date")
		typesOrder.reverse();

	for(let i = 0; i < typesOrder.length; i++){
		const type = typesOrder[i];
		for(let j = 0; j < type.order.length; j++)
			if(text.indexOf(type.order[j]) == 0)
				return {
					format: type.types[type.order[j]],
					length: type.order[j].length,
					type: type.name
				};
	}
}

function genFormatCode(line, delimiters){
	if (!line)
		return "return val;";

	let str = "return \"\"";

	let isQuote;
	let fmt = "";
	let multiply = 1;
	let comma, numberPlaceholder, textPlaceholder;
	let date = [];
	let lastDateType = "date";

	for (var i=0; i<line.length; i++){
		var betweenPlaceholders = placeholder(line[i-1])&&placeholder(line[i+1]);
		if (line[i] == "\""){
			if(webix.isUndefined(isQuote))
				isQuote = i;
			else {
				str += `+"${line.substr(isQuote+1, i-isQuote-1)}"`;
				isQuote = undefined;
			}
			continue;
		}
		if (!webix.isUndefined(isQuote))
			continue;
		if(line[i] == "%")
			multiply = multiply*100;

		if(/[a-zA-Z]/.test(line[i])){
			const datePart = excelDateToWebix(line.substr(i), lastDateType);
			if(datePart){
				if(numberPlaceholder || textPlaceholder)
					return "throw \"unexpected number placeholder\";";
				date.push(datePart.format);
				str += "/*date*/"; //update this value after date format is ready
				lastDateType = datePart.type;
				i += datePart.length-1;
				continue;
			}
			else
				str += `+"${line[i]}"`;
		}
		else if (line[i] === delimiters.decimalSign && betweenPlaceholders){
			fmt+= delimiters.decimalSign;
		}
		else if(line[i]=="@"){
			if(numberPlaceholder || date.length)
				return "throw \"unexpected number placeholder\";";
			if(!textPlaceholder){
				str+="+fmt";
				textPlaceholder = true;
			}
			fmt+=line[i];		
		}
		else if (placeholder(line[i])){
			if(textPlaceholder || date.length)
				return "throw \"unexpected text placeholder\";";
			if (!numberPlaceholder) {
				str+="+fmt";
				numberPlaceholder=true;
			}
			fmt+=line[i];
		}
		else if (line[i] === delimiters.groupSign && betweenPlaceholders)
			comma = true;
		else
			str += `+"${line[i]}"`;
	}

	if(date.length){
		// 12-hour or 24-hour clock depends on AM/PM in format
		if(date.indexOf("%A") != -1)
			date = date.map(datePart => {
				switch(datePart){
					case "%G": return "%g";
					case "%H": return "%h";
					default: return datePart;
				}
			});

		for(let i = 0; i < date.length; i++)
			str = str.replace("/*date*/", `+dateParts[${i}]`);
	}

	return `
		${date.length ? `val = (${getJsDateFromExcel})(val); var dateParts = webix.Date.dateToStr("${date.join(";")}")(val).split(";");` : ""}
		${fmt ? `${numberFormat(fmt, comma, delimiters, multiply)};` : ""}
		${str};`;
}

function numberFormat(fmt, comma, delimiters, multiply){
	var str = `if(/^@+$/.test("${fmt}") || isNaN(val)){ var fmt = val; }else {`;
	var decimal = delimiters.decimalSign;
	var [left, right] = getParts(fmt, decimal);
	right = (right || "").split("").reverse().join("");

	//calculate indexes in mask
	var lzeros = left.indexOf("0"); if (lzeros>=0) lzeros = left.length - lzeros;
	var lfills = left.indexOf("?"); if (lfills>=0) lfills = left.length - lfills;
	var rzeros = right.indexOf("0"); if (rzeros>=0) rzeros = right.length - rzeros;
	var rfills = right.indexOf("?"); if (rfills>=0) rfills = right.length - rfills;
	var rmax = right.length;

	str += `
	val = val*${multiply};
	var parts = val.toFixed(${rmax}).split(".");
	var left = parts[0];
	var lsize = left.length; 
	var right = parts[1] || "";
	if (left.length < ${lzeros}) left = "0000000000".substr(0, ${lzeros} - left.length)+left;
	if (left.length < ${lfills}) left = spaces.substr(0, 6*(${lfills} - left.length))+left;
	if (${comma}) {
		var buf = [];
		var start = 3;
		while (lsize > start) { buf.push(left.substr(left.length-start,3)); start+=3; }
		var last = left.substr(0,left.length-start+3);
		if (last !== "-")
			buf.push(last);
		else
			buf.push("-"+buf.pop());

		left = buf.reverse().join("${delimiters.groupSign}");
	}
	if (right){
		var zpoint = right.length-1;
		while (zpoint >= ${rzeros}){
			if (right[zpoint] !== "0"){
				break;
			}
			zpoint--;
		}

		if (zpoint <= right.length)
			right = right.substr(0, zpoint+1);

		if (right.length < ${rfills}){
			right += spaces.substr(0, 6*(${rfills} - right.length));
		}
	}
	var fmt = left+(right?"${decimal}":"")+right;
	`;

	return str+"}\n";
}

function updateDecimals(fmt, inc, val){
	let parsed = parseFormat(fmt);
	for(let i = 0; i < parsed.length; i++){
		if(parsed[i].decimals){
			if(inc>0)
				parsed[i].right.placeholders += Array(inc+1).join("0");
			else
				parsed[i].right.placeholders = parsed[i].right.placeholders.slice(0, inc);
		}
		else{
			const withoutText = !parsed[i].left.text && !parsed[i].right.text;
			const withoutPlaceholders = !parsed[i].left.placeholders && !parsed[i].right.placeholders;
			//check if cell value have decimals (not cell format)
			if(inc < 0 && withoutText && withoutPlaceholders && webix.rules.isNumber(val) && val.indexOf(".") > -1){
				parsed[i].left.placeholders = "0";
				parsed[i].right.placeholders = Array(val.split(".")[1].length).join(Array(inc+1).join("0"));
			}
			//ignore text
			else if(inc > 0 && (parsed[i].left.placeholders || withoutText)){
				parsed[i].left.placeholders = parsed[i].left.placeholders || "0";
				parsed[i].right.placeholders = Array(inc+1).join("0");
				parsed[i].decimals = true;
			}
		}
		parsed[i].decimals = parsed[i].right.placeholders.length > 0;
	}

	return serializeFormat(parsed);
}

export function changeAreaDecimals(view, inc, row, column){
	let cells = getCells(view, row, column);
	if(cells.length == 0)
		return;

	const item = view.$$("cells").getItem(cells[0].row)[cells[0].column];
	const firstValue = item ? item.toString() : item;
	const firstStyle = view.getStyle(cells[0].row, cells[0].column);

	if(webix.rules.isNumber(firstValue) || (firstStyle && firstStyle.props.format)){
		group.set(function(){
			for(let i = 0; i < cells.length; i++)
				changeCellDecimals(view, inc, cells[i]);
		});
		view.refresh();

		let format = view.getStyle(cells[0].row, cells[0].column).props.format || "common";
		format = isCustom(format) ? "custom" : format;
		view.callEvent("onCommand", [{id:"toolbar-update", name:"format", value:format}]);
	}
}

export function changeCellDecimals(view, inc, cell){
	let format;
	const style = view.getStyle(cell.row, cell.column);
	let value = view.$$("cells").getItem(cell.row)[cell.column];
	value = value ? value.toString() : "";

	if(style && style.props.format){
		const name = style.props.format;
		format = webix.copy({values:formatHelpers[name].values, format:getFormatSource(name)});
		format.format = updateDecimals(format, inc, value);

		if(format.values && format.values.hasOwnProperty("zeros"))
			format.values.zeros = Math.max(format.values.zeros+inc, 0);
	}
	//add format for cell without format
	else{
		const zeros = webix.rules.isNumber(value) && value.split(".")[1] ? Math.max(value.split(".")[1].length+inc, 0) : Math.max(inc, 0);

		format = {values:{negative:1, zeros:zeros, separator:0, type:"int"}};
		format.format = serializeFormat(formToValues(format.values.type, format.values));
	}
	addFormat(view, cell.row, cell.column, format.format, format.values);
}

export function isCustom(name){
	return name != "common" && (name.indexOf("fmt") != -1 || formatHelpers[name].values.type == "custom");
}

export function formToValues(type, formValues){
	if(type == "string")
		return [{left:{text:"@"}}];
	if(formValues.date)
		return [{left:{text:formValues.date}}];

	let values = [{condition:">=0", left:{text:""},right:{text:""}}, {condition:"<0", left:{text:""}, right:{text:""}}];
	webix.extend(values, getDelimiters(type));
	values[0].right.placeholders = values[1].right.placeholders = formValues.zeros ? Array(formValues.zeros + 1).join("0") : "";
	values[0].separator = values[1].separator = formValues.separator && type != "percent";
	values[0].left.placeholders = values[1].left.placeholders = values[0].separator ? "#"+values.groupSign +"0" : "0";
	values[0].decimals = values[1].decimals = formValues.zeros ? true : false;
	values[1].color = formValues.negative != 1 ? "red" : "";
	values[1].left.text += formValues.negative < 3 ? "-" : "";
	if(formValues.negative == 4)
		values[1].left.text += "(";

	if(type === "percent")
		values[0].right.text = values[1].right.text += "%";
	else if(type === "price"){
		const symbol = formatHelpers.price.values.symbol;
		const right = symbol.indexOf(" ") == 0;

		values[1][right?"right":"left"].text += symbol;
		values[0][right?"right":"left"].text += symbol;
	}

	if(formValues.negative == 4)
		values[1].right.text += ")";

	return values;
}

export function serializeFormat(values, toExcel){
	let fmt = "";
	for(let i = 0; i < values.length; i++){
		if(values[i].condition)
			fmt += "["+values[i].condition+"]";
		if(values[i].color)
			fmt += "["+values[i].color+"]";

		if(values[i].left){
			if(values[i].left.text){
				let leftText = values[i].left.text;
				fmt += leftText;
			}
			if(values[i].left.placeholders){
				let leftPlaceholders = values[i].left.placeholders;
				fmt += toExcel ? leftPlaceholders.replace(values.groupSign, ",") : leftPlaceholders;
			}
		}

		if(values[i].decimals)
			fmt += toExcel ? "." : values.decimalSign;

		if(values[i].right){
			if(values[i].right.placeholders)
				fmt += values[i].right.placeholders;
			if(values[i].right.text){
				let rightText = values[i].right.text;

				fmt += rightText;
			}
		}
		if(i != values.length - 1)
			fmt += ";";
	}
	return fmt;
}

function parseFormat(fmt){
	const fmtParts = fmt.format.split(";");
	let values = [];

	for(let i = 0; i < fmtParts.length; i++){
		let separated = separateFmt(fmtParts[i]);
		values[i] = {color:separated.color, condition:separated.condition, left:{}, right:{}};

		const format = separated.fmt;

		values.groupSign = fmt.values.groupSign;
		values.decimalSign = fmt.values.decimalSign;

		if(format.indexOf(values.groupSign) > -1)
			values[i].separator = true;

		const sepFmtParts = getParts(format, values.decimalSign);

		let left = sepFmtParts[0];
		let right = sepFmtParts[1] || "";

		if(sepFmtParts[1])
			values[i].decimals = true;

		left = getFmtPartValues(left, "left", values.groupSign);
		right = getFmtPartValues(right, "right", values.groupSign);

		values[i].left = {
			text: left.leftText,
			placeholders: left.placeholders
		};

		values[i].right = {
			text: left.rightText + right.rightText,
			placeholders: right.placeholders
		};
	}
	return values;
}

function getFmtPartValues(fmtPart, type, groupSign){
	let firstPlaceholder;
	if(type == "left"){
		firstPlaceholder = fmtPart.match(/[#?0]/);
		firstPlaceholder = firstPlaceholder ? firstPlaceholder.index : Infinity;
	}

	let leftText = "";
	let rightText = "";
	let placeholders = "";
	for(let k = 0; k < fmtPart.length; k++){
		if(!placeholder(fmtPart[k])){
			if(fmtPart[k] == groupSign && placeholder(fmtPart[k+1]) && placeholder(fmtPart[k-1])){
				placeholders += fmtPart[k];
				continue;
			}
			if(type == "left")
				if(k < firstPlaceholder){
					leftText += fmtPart[k];
					continue;
				}
			rightText += fmtPart[k];
		}
		else
			placeholders += fmtPart[k];
	}
	return {leftText: leftText, rightText: rightText, placeholders: placeholders};
}

function separateFmt(line){
	let color = "";
	let check = "";
	let start = line.indexOf("[");
	if (start !== -1){
		if (line[1].match(/[><=]/)){
			let end = line.indexOf("]");
			check = line.substr(start+1, end-start-1);
			line = line.substr(end+1);
		}
	}

	start = line.indexOf("[");
	if (start !== -1){
		let end = line.indexOf("]");
		color = line.substr(start+1, end-start-1);
		line = line.substr(end+1);
	}
	return {condition:check, color:color, fmt:line};
}

function getCells(view, row, column){
	if(row && column){
		if (typeof row === "object" && typeof column === "object"){
			let cells = [];
			for (let r = row.row; r<=column.row; r++)
				for (let c = row.column; c<=column.column; c++)
					cells.push({row: r, column: c});
			return cells;
		}
		else
			return [{row: row, column: column}];
	}
	else
		return view.getSelectedId(true);
}

function placeholder(val){
	return val === "0" || val === "#" || val === "?";
}

function getParts(str, decimal){
	// it is nessesary to use "/" before decimal to escape regex special characters
	const match = str.match("[#?0][/"+decimal+"][#?0]");
	if(match)
		return [str.substring(0, match.index+1), str.substring(match.index+2)];
	else
		return [str];
}

//simple check: take into account only first condition
export function checkFormat(format, type, checkTime){
	format = format && format.fmt ? format.fmt : getFormatSource(format);
	if(!format) return;
	const parts = splitFormat(format);
	const separated = separateFmt(parts[0]).fmt;

	let quot;
	if(separated)
		for(let i = 0; i < separated.length; i++){
			if(separated[i] == "\"")
				quot = !quot;
			if(quot) continue;

			if(type == "date" && /[a-zA-Z]/.test(separated)){
				const datePart = excelDateToWebix(separated.substr(i), "date");
				if(datePart){
					if(checkTime){
						if(datePart.type == "time")
							return true;
					}
					else
						return true;
				}
			}

			if(type == "string" && separated[i] == "@")
				return true;
		}
}

export function getFormatName(view, row, col){
	const item = view.getRow(row);
	return item && item.$cellFormat && item.$cellFormat[col];
}

export function isDateInvalid(value){
	return value < -99974430.925 || value > 100025569.125;
}

export function getDateEditFormat(item, col){
	const formatName = item.$cellFormat[col];
	const locale = webix.i18n.spreadsheet.formats;
	const isTime = checkFormat(formatName, "date", true);

	return webix.Date.dateToStr( locale[isTime ? "parseDateTime" : "parseDate"] );
}