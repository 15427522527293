import {Dialog} from "./common";
import {rangeValues} from "../math/ranges";
import {range} from "../helpers/column_names";
import {parseChart} from "../math/writer";

export const action = "add-sparkline";

const labels = webix.i18n.spreadsheet.labels;

function getTypes(){
	return  [
		{id:"line", value:labels["line-chart"]},
		{id:"spline", value:labels["spline-chart"]},
		{id:"splineArea", value:labels["splinearea-chart"]},
		{id:"area", value:labels["area-chart"]},
		{id:"bar", value:labels["bar-chart"]},
		{id:"pie", value:labels["pie-chart"]}
	];
}

export class DialogBox extends Dialog{
	$show(box, form){
		this.cell = this.view.getSelectedId();
		if(!this.cell)
			return false;

		var els = form.elements;
		this.view.$handleSelection = function(st, end, a, b){
			els.range.setValue(a+":"+b);
			return false;
		};

		els.range.setValue("");

		//sync state of dialog to data with  the selected cell
		let value = this.view.getCellValue(this.cell.row, this.cell.column);
		let data = parseChart(value);
		if (data){
			form.blockEvent();
			els.type.setValue(data.type);
			els.range.setValue(data.range);
			if (data.color){
				els.color_def.setValue(data.color);
				els.color_pos.setValue(data.color);		
			}
			if (data.negativeColor)
				els.color_neg.setValue(data.negativeColor);
			form.unblockEvent();
			renderPreview(this);
		}	

		els.range.focus();
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	$init(){
		return {
			view: "ssheet-dialog",
			head:labels["sparkline-title"],
			move: true,
			position: "center",
			body:{
				view:"form", visibleBatch:1, on:{ onChange : ()=> renderPreview(this) },  elements:[
					{view: "richselect", name:"type", label:labels["sparkline-type"], value:"line", labelPosition:"left", suggest:{
						view:  "ssheet-form-suggest",
						data:getTypes()
					}, on:{
						"onChange": typesChangeHandler
					}},
					{ view:"text", label:labels["sparkline-range"], name:"range"},
					{ view:"ssheet-colorpicker", label:labels["sparkline-color"], name:"color_def", id:"add_sparkline_color", value:"#6666FF", batch:"1"},
					{ view:"ssheet-colorpicker", label:labels["sparkline-positive"], name:"color_pos", value:"#6666FF", batch:"2"},
					{ view:"ssheet-colorpicker", label:labels["sparkline-negative"], name:"color_neg", value:"#FF6666", batch:"2"},
					{ view:"formlate", name:"preview", borderless:true, css:"webix_ssheet_preview", height:50 }
				]
			},
			on:{
				onSaveClick: () => okClick(this),
				onCancelClick: () => this.close()
			}
		};
	}
	checkRange(text){
		if(text && range(text, this.view))
			return true;
		this.view.alert({text:labels["error-range"]});
	}
}

function renderPreview(dialog){
	var form = dialog.$dialog.getBody();
	var data = form.getValues();

	if (data.range && dialog.checkRange(data.range)){
		let i,
			values = rangeValues(dialog.view, data.range),
			config = sparkConfig(form);
		for(i =0 ; i < values.length; i++)
			values[i] = values[i]||0;
		form.elements.preview.setValue(webix.Sparklines.getTemplate(config)(values, { width:200, height: 40 }));
	}
}

function sparkConfig(form, config){
	var data = form.getValues();
	config = config || { type: data.type  };
	if (data.type === "bar"){
		config.color = data.color_pos;
		config.negativeColor = data.color_neg;
	}
	else if(form.elements.color_def.isVisible())
		config.color = data.color_def;

	return config;
}

function okClick(dialog){
	var form = dialog.$dialog.getBody();
	var config = sparkConfig(form, form.getValues());
	if (dialog.checkRange(config.range)){
		dialog.view.addSparkline(dialog.cell.row, dialog.cell.column, config);
		dialog.close();
	}
}




function typesChangeHandler(type){
	var form = this.getFormView();
	switch(type){
		case "pie":
			form.showBatch(3);
			break;
		case "bar":
			form.showBatch(2);
			break;
		default:
			form.showBatch(1);
	}
}