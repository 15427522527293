import { style_names } from "../operations/styles";
import { fontFamily} from "../toolbar/buttons";
import { defaultStyles} from "../toolbar/defaults";

export function init(view){
	view._parseExcel = function(obj, driver){
		var options = obj.options || {};
		options.math = view.config.math;

		var full = { sheets:[]};
		for(let i  = 0; i< obj.names.length; i++){
			options.name = obj.names[i];
			full.sheets.push({
				name:options.name,
				content:_excel_to_data(driver.getSheet(obj, options), obj.ranges, i)
			});
		}
		return full;
	};
	view._parseCsv = function(obj, driver){
		obj = driver.getRecords(obj);

		var dataObj = webix.copy(obj);
		var data = [];

		for(let i = 0; i<dataObj.length; i++){
			let item = dataObj[i].split(driver.cell);
			for(let c  = 0; c<item.length; c++){
				var row = i+1, column = c+1;
				data.push([row, column, item[c]]);
			}
		}
		return { data:data };
	};
}


function _excel_to_data(obj, ranges, index){
	var dataObj = webix.copy(obj.data);
	var data =  [];

	for (let i = 0; i < dataObj.length; i++){
		for(let c = 0; c<dataObj[0].length; c++){
			var row = i+1, column = c+1;
			data.push([row, column, dataObj[i][c]]);
		}
	}
	obj.data = data;

	//merged cells
	if(obj.spans){
		for(let i = 0; i<obj.spans.length; i++){
			obj.spans[i][0]++;
			obj.spans[i][1]++;
		}
	}

	//named ranges
	if(ranges){
		for(let r = 0; r<ranges.length; r++){
			//we don't support global named ranges, so import only sheet-based :(
			if(ranges[r].Sheet === index){
				obj.ranges = obj.ranges || [];
				obj.ranges.push([ranges[r].Name.toUpperCase(), ranges[r].Ref.substring(ranges[r].Ref.indexOf("!")+1).replace(/\$/g, "")]);
			}
		}
	}

	//cell styles
	if(obj.styles)
		_getStyles(obj);

	if(obj.types)
		_getTypes(obj);

	//column width and row height
	if(obj.sizes){
		for(let i = 0; i<obj.sizes.length; i++){
			if(obj.sizes[i][0] == "column")
				obj.sizes[i] = [0, obj.sizes[i][1]+1, obj.sizes[i][2]];
			else
				obj.sizes[i] = [obj.sizes[i][1]+1, 0, obj.sizes[i][2]];
		}
	}

	return obj; 
}

//ARGB conversion
function _safeColor(str){
	str = str || "000000";
	if(str.length === 8) str = str.substring(2);
	return "#"+str;
}

//only fonts that we support can be imported
function _safeFont(str){
	let safe = defaultStyles["font-family"];
	for(let i = 0; i<fontFamily.length; i++){
		if(fontFamily[i].value == str) { safe = fontFamily[i].id; break; }
	}
	return safe;
}

function _getTypes(obj){
	obj.types.forEach(type => {
		for(let i = 0; i < obj.data.length; i++){
			if(obj.data[i][0] === type[0]+1 && obj.data[i][1] === type[1]+1){
				obj.data[i][4] = type[2];
				break;
			}
		}
	});
}

function _getBorderStyles(pos, stl){
	pos = pos.replace("border-", "");
	if(stl.border && stl.border[pos]){
		const border = stl.border[pos];
		const color = _safeColor(border.color.rgb) || "";
		const type = border.style || "";
		return `${color},${type}`;
	}
	return "";
}

function _getStyles(obj){
	let styleshash = {}, styles = [], stylescount = 1;
	for(let i = 0; i<obj.styles.length; i++){
		let str = [], stl = obj.styles[i][2], css;

		for(let s  = 0; s<style_names.length; s++){
			switch (style_names[s]){
				case "color": 
					str[s] = stl.font && stl.font.color?(_safeColor(stl.font.color.rgb)||""):"";
					break;
				case "background":
					str[s] = stl.fill && stl.fill.fgColor?(_safeColor(stl.fill.fgColor.rgb) ||""):"";
					break;
				case "text-align":
					str[s] = (stl.alignment ?stl.alignment.horizontal:"") || defaultStyles["text-align"];
					break;
				case "font-family":
					str[s] = stl.font && stl.font.name?_safeFont(stl.font.name):"";
					break;
				case "font-size":
					str[s] = stl.font && stl.font.sz?((stl.font.sz/0.75)+"px" ||""):""; //pt to px conversion
					break;
				case "font-style":
					str[s] = stl.font && stl.font.italic?"italic":"";
					break;
				case "text-decoration":
					str[s] = stl.font && stl.font.underline?"underline":"";
					break;
				case "font-weight":
					str[s] = stl.font && stl.font.bold?"bold":"";
					break;
				case "vertical-align":
					var va = stl.alignment ? (stl.alignment.vertical || ""):"";
					str[s] = (va =="center"?"middle":va) || defaultStyles["vertical-align"];
					break;
				case "wrap":
					str[s] = stl.alignment && stl.alignment.wrapText?"wrap":"nowrap";
					break;
				case "borders":
					str[s] = "";
					break;
				case "format":
					str[s] = ""; //stl.numFmt
					break;
				case "border-right":
				case "border-bottom":
				case "border-left":
				case "border-top":
					str[s] = _getBorderStyles(style_names[s], stl);
					break;
			}
		}
		str = str.join(";");
		css = styleshash[str] || "wss"+stylescount;

		for(let d = 0; d<obj.data.length; d++){
			if(obj.data[d][0] === obj.styles[i][0]+1 && obj.data[d][1] === obj.styles[i][1]+1){
				obj.data[d][3] = css;
				break;
			}
		}
		if(!styleshash[str]){
			styles.push([css, str]);
			styleshash[str] = css;
			stylescount++;
		}
	}
	obj.styles = styles;
}