import * as methods from "./math/methods";
import {getJsDateFromExcel} from "./math/methods/helpers";
import {getDateEditFormat} from "./operations/formats";
import {getType} from "./operations/types";

let methodsName = [];
for(let i in methods.methods)
	if( i!="__esModule") methodsName.push(i);

export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));

	return {
		view:"toolbar",
		css:"webix_ssheet_toolbar",
		elements:[
			{
				view:"label",
				label:webix.i18n.spreadsheet.liveEditor["edit"],
				width:60
			},
			{
				view:"live-editor",
				disabled:true,
				id:"liveEditor",
				suggestData: methodsName
			}
		]
	};
}

function ready(view){
	var editor = view.$$("liveEditor");

	//block native editor, and move focus to the custom input
	//edit by key press
	view._table.attachEvent("onBeforeEditStart", function(cell){
		let mode = view._table.$anyKey;
		view._table.$anyKey = false;

		return startEdit(this, cell, mode);
	});
	//edit by enter key
	view._table.attachEvent("onBeforeLiveEditor", function(cell){
		return startEdit(this, cell, false);
	});
	//edit by dbl-click
	view._table.attachEvent("onItemDblClick", function(id){
		if(view.getCellEditor(id.row, id.column))
			this.edit(id);
		editor.focus();
	});

	view._table.attachEvent("onAfterScroll", function(){
		if(editor.$view.contains(document.activeElement))
			editor.paintValue();
	});

	view.attachEvent("onCellChange", (r,c,v) => {
		var cell = editor.config.activeCell;
		if (cell && cell.row == r && cell.column == c)
			editor.setValue(v);
	});

	view.attachEvent("onAfterSelect", (data) => {
		if (!view.$handleSelection){
			let cell = data[0];
			fillEditor(cell);
		}
	});

	view.attachEvent("onChange", (mode) => {
		if (mode == "update" && editor.isEnabled()){
			const cell = editor.config.activeCell;
			if(cell && editor.getValue() != view.getCellValue(cell.row, cell.column)){
				delete editor._update_range;
				editor.showActiveSheet();
				editor.updateCellValue();
			}
		}
	});

	view.attachEvent("onBeforeSheetShow", (name)=> {
		const cell = editor.config.activeCell;
		if(cell){
			const cursor = editor.getInputNode().selectionStart;
			const val = editor.getValue();
			if(view.getCellValue(cell.row, cell.column) != val){
				if(!editor._activeMath)
					editor._activeMath = view.getActiveSheet();
				webix.delay(()=>{
					if(name == editor._activeMath){
						view.$handleSelection = null;
						const cell = editor.config.activeCell;
						view._table.select(cell.row, cell.column);
					}

					editor.setValue(val);
					//update highlight
					editor.refresh();

					editor.focus();
					editor.getInputNode().setSelectionRange(cursor, cursor);
					editor.paintValue();

					view.$handleSelection = function(a,b,st,en){ return pasteRange(st, en, a); };
				});
			}
			else
				clearEditor();
		}
	});

	view.attachEvent("onReset", () => view.$handleSelection = null);

	view.attachEvent("onAfterLoad", () => clearEditor());

	view.attachEvent("onAction", function(name, options){
		const cell = editor.config.activeCell;
		const isActiveCell = cell && cell.row == options.row && cell.column == options.column;
		if(isActiveCell && (name == "lock" || name == "dropdown"))
			disableEditor(options.row, options.column);
	});

	view.attachEvent("onFormatChange", (r, c) => updateEditor(r, c));
	view.attachEvent("onCellChange", (r, c) => updateEditor(r, c));

	function updateEditor(row, column){
		const activeCell = editor.config.activeCell;
		if(activeCell && activeCell.row == row && activeCell.column == column)
			fillEditor({row, column});
	}

	function startEdit(table, cell, clear){
		//do not interfere with custom editors
		if (!view.getCellEditor(cell.row, cell.column) && fillEditor(cell, clear)){
			editor.focus();
			if(!clear)
				editor.paintValue();
			return false;
		}
		return true;
	}

	function disableEditor(row,column){
		const disabled = view.getCellEditor(row, column) || view.isCellLocked(row, column);
		if(disabled)
			editor.disable();
		else
			editor.enable();
		return disabled;
	}

	function fillEditor(cell, clear){
		const disabled = disableEditor(cell.row, cell.column);
		editor.config.activeCell = cell;
		editor.setValue( clear ? "" : getCellValue(cell.row, cell.column) );

		view.$handleSelection = disabled ? null : function(a,b,st,en){ return pasteRange(st, en, a); };
		return !disabled;
	}

	function getCellValue(row, column){
		let value = view.getCellValue(row, column);
		if(getType(view, row, column) == "date" && value[0] != "="){
			const format = getDateEditFormat(view.getRow(row), column);
			value = format(getJsDateFromExcel(value));
		}
		return value;
	}

	function pasteRange(st, en, cell){
		let cursor = editor.getInputNode().selectionStart;

		let sheet = "";
		if(editor._activeMath){
			const activeSheet = view.getActiveSheet();
			if(editor._activeMath != activeSheet)
				sheet = editor.prepareSheet(activeSheet);
		}

		const range = st == en ? sheet+st : `${sheet+st}:${sheet+en}`;
		const updateRange = editor._update_range;

		if(updateRange && updateRange.pos == cursor - updateRange.len){
			editor.setRange(range, true);
			cursor = editor._update_range.pos + range.length;
		}
		else if(editor.expectOperator()){
			editor.setRange(range);
			cursor += range.length;
		}
		else
			return endEdit(cell);

		editor.focus();
		editor.getInputNode().setSelectionRange(cursor, cursor);
		editor.paintValue();

		return false;
	}

	function endEdit(st){
		if (editor.isEnabled()){
			editor.showActiveSheet();
			editor.updateCellValue();
			editor.setValue(st ? view.getCellValue(st.row, st.column) : "");
		}
		return true;
	}

	function clearEditor(){
		editor.define({activeCell:null});
		editor.setValue("");
		editor.disable();
	}
}
