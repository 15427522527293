export const toolbarSizes = {
	width:38,
	margin: 7,
	paddingY: 1,
	sectorPadding: 5,
	sectorMargin: 0
};

// default styles for toolbar elements
export const defaultStyles = {
	"color"         : "#666666",
	"background"    : "#ffffff",
	"font-family"   : "'PT Sans', Tahoma",
	"font-size" : "15px",
	"text-align": "left",
	"vertical-align": "middle",
	"white-space": "nowrap",
	"format": "common"
};

export function setSkinDefaults(){
	const skin = webix.skin.$name;
	const material = skin == "material" || skin == "mini";
	toolbarSizes.width = webix.skin.$active.inputHeight+2;
	toolbarSizes.titleHeight = webix.skin.$name == "mini" ? 18 : 20;

	defaultStyles["font-family"] = material ? "'Roboto', sans-serif" : "'PT Sans', Tahoma";

	if(skin == "contrast"){
		defaultStyles.background = "#393939";
		defaultStyles.color = "#ffffff";
	}
}