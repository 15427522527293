import {range, toRange} from "./helpers/column_names";
import {_getSeries, _isPie, _normalizeConfig, _getDataParts} from "./operations/views";

const labels = webix.i18n.spreadsheet.labels;

export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));
	const activeSkin = webix.skin.$active;
	const padding = activeSkin.dataPadding-2;
	const margin = activeSkin.layoutMargin.form;

	return {
		width:420,
		hidden: true,
		id:"chartEditor",
		css:"ssheet_chart",
		rows:[
			{
				height: activeSkin.barHeight,
				css: "ssheet_chart_wizard_head",
				padding:{ left: 2 },
				cols: [
					{ view: "icon", hotkey:"esc", icon:"webix_ssheet_icon ssi-arrow-left", click: () => closeEditor(view)  },
					{ type:"header", borderless:true, template: labels["chart-edit"] }
				]
			},
			{
				view:"form",
				complexData:true,
				borderless: true,
				padding:{
					top: 0
				},
				elementsConfig:{
					labelWidth:180
				},
				elements:[{
					view:"tabview",
					id:"chartTabs",
					cells:[
						{ id:"common", borderless:true, type:"form", paddingX:0, header:labels["chart-common"], rows:[
							{view:"scrollview", body:{
								margin,
								rows:[
									addRichselect("type", "chart-type", getTypes()),
									{ view:"checkbox", label:labels["stacked-chart"], name:"stacked", hidden:true},
									{ view:"checkbox", label:labels["chart-pie-3d"], name:"3D", hidden:true},
									{ view:"text", label:labels["chart-range"], name:"range"},
									{ view:"checkbox", label:labels["chart-col-xAxis"], name:"xAxis.fromData", batch:"axis"},
									{ view:"checkbox", label:labels["chart-pie-text"], name:"pieInnerText", hidden:true},
									{ view:"checkbox", label:labels["chart-row-legend"], name:"legend.fromData"},
									{ view:"radio", label:labels["chart-data-from"], name:"dataSeries", vertical:true, options:[
										{id:"columns", value:labels["chart-columns"]},
										{id:"rows", value:labels["chart-rows"]}
									]},
									{}
								]
							}}
						]},
						{ id:"advanced", borderless:true, type:"form", paddingX:0, padding:{bottom:0}, header:labels["chart-series"], rows:[
							{ view: "ssheet-series", name:"series" },
						]},
						{ id:"extras", borderless:true, type:"form", paddingX:0, header:labels["chart-extras"], rows:[
							{view:"scrollview", body:{
								view: "accordion",
								margin: webix.skin.$active.layoutMargin.form,
								css: "webix_ssheet_accordion",
								multi: true,
								type: "clean",
								rows:[
									{ header:labels["chart-legend"], body:{
										margin,
										padding,
										rows:[
											{ view: "text", label:labels["chart-legend-range"], name:"legend.range"},
											addRichselect("legend.align", "chart-legend-align", getLegendAlign()),
											addRichselect("legend.valign", "chart-legend-valign", getLegendValign())
										]
									}},
									{ header:labels["chart-axis"], body:{
										margin,
										padding,
										rows:[
											{ view:"text", label:labels["chart-xAxis-range"], name:"xAxis.range", range:true, batch:"axis" },
											{ view:"text", label:labels["chart-xAxis-title"], name:"xAxis.title", batch:"axisTitle" },
											{ view:"text", label:labels["chart-yAxis-title"], name:"yAxis.title", batch:"axisTitle" },
											{ view:"checkbox", label:labels["chart-scale"], name:"scale.lines", batch:"axis"},
											{ view:"checkbox", label:labels["chart-radar-circle"], name:"scale.circle", hidden:true},
											{ view:"ssheet-colorpicker", label:labels["chart-scale-color"], name:"scale.color", batch:"axis"}
										]
									}},
									{}
								]
							}}
						]}
					]
				}]
			}
		]
	};
}

function ready(view){
	const chartEditor = view.$$("chartEditor");
	const form = chartEditor.queryView("form");
	const elements = form.elements;

	view.attachEvent("onCommand", action => {
		if(action.id == "add-chart"){
			const config = action.config;
			let values = config && config.config || {};

			chartEditor.show();

			view.$handleSelection = function(st, end, a, b){
				const focus = webix.UIManager.getFocus();

				if(chartEditor.queryView(view => view == focus)){
					if(focus == elements.range || focus == elements["legend.range"]){
						let val = focus.getValue();
						const last = val.lastIndexOf(",");

						val = last == -1 ? "" : val.substring(0, last + 1);
						focus.setValue(val + a+":"+b);
					}
					else if(focus.config.range)
						focus.setValue(a+":"+b);
				}

				return false;
			};

			form.clear();
			view.$$("chartTabs").setValue("common");

			values = _normalizeConfig(values);

			values.range = config ? config.data : view.getSelectedRange();
			chartEditor.viewId = action.viewid ? action.viewid : view.views.add(null, null, "chart", values.range, values);

			view.callEvent("onUIEditStart", [chartEditor.viewId, "chart"]);

			updateValign(values.legend.align);
			updateAlign(values.legend.valign);
			updateType(values.type);

			form.setValues(inValues(values));

			if(values.legend.fromData)
				updateLegendRange(values.legend.fromData);
			if(values.xAxis.fromData)
				updateXaxisRange(values.xAxis.fromData);

			updateSeries();

			form.queryView("accordionitem", "all").forEach(view => view.expand());

			elements.range.focus();
		}
	});

	view.attachEvent("onUIEditStart", (id, type)=>{
		if(chartEditor.isVisible() && type != "chart")
			closeEditor(view);
	});

	view.attachEvent("onCommand", obj =>{
		if(obj.id == "close-ui-editor" && chartEditor.isVisible())
			closeEditor(view);
	});

	view.attachEvent("onAction", (id, values) => {
		if(id == "add-view" && !values.newValue){
			if(chartEditor.isVisible() && chartEditor.viewId == values.row)
				closeEditor(view);
		}
	});

	form.attachEvent("onChange", function(val, oldVal){
		switch (this.$eventSource){
			case elements.type:
				updateType(val);
				break;
			case elements.range:
			case elements.dataSeries:
				if(this.$eventSource == elements.dataSeries)
					switchRowsCols();
				if(elements["legend.fromData"].getValue())
					handlePieLegend();
				if(elements["xAxis.fromData"].getValue())
					setSilentValue(elements["xAxis.range"], getLeftColumn());
				updateSeries();
				break;
			case elements["xAxis.fromData"]:
				updateXaxisRange(val);
				updateSeries(oldVal);
				break;
			case elements["legend.fromData"]:
				updateLegendRange(val);
				updateSeries();
				break;
			case elements["legend.align"]:
				updateValign(val);
				break;
			case elements["legend.valign"]:
				updateAlign(val);
				break;
			case elements["xAxis.range"]:
				if(elements["xAxis.fromData"].getValue())
					setSilentValue(elements["xAxis.fromData"]);
				updateRange(val, oldVal);
				break;
			case elements["legend.range"]:
				if(elements["legend.fromData"].getValue())
					setSilentValue(elements["legend.fromData"]);
				updateRange(val, oldVal);
				break;
		}

		const config = outValues(form.getValues());
		view.views.update(chartEditor.viewId, config.range, config);
	});

	elements.series.attachEvent("onRangeChange", updateRange);

	function handlePieLegend(){
		if(elements["legend.fromData"].getValue()){
			const handler = _isPie(elements.type.getValue()) ? getLeftColumn : getTopRow;
			setSilentValue(elements["legend.range"], handler());
		}
	}

	function updateLegendRange(val){
		if(val){
			const legendRange = elements["legend.range"].getValue();
			updateRange("", legendRange);
		}

		const handler = _isPie(elements.type.getValue()) ? getLeftColumn : getTopRow;
		val = val ? handler() : "";

		setSilentValue(elements["legend.range"], val);
	}

	function updateXaxisRange(val){
		if(val){
			const xAxisRange = elements["xAxis.range"].getValue();
			updateRange("", xAxisRange);
		}

		val = val ? getLeftColumn() : "";

		setSilentValue(elements["xAxis.range"], val);
	}

	function setSilentValue(element, val){
		element.blockEvent();
		element.setValue(val);
		element.unblockEvent();
	}

	function getLeftColumn(){
		const data = elements.range.getValue();
		const dataSeries = elements.dataSeries.getValue();
		const legendRange = elements["legend.range"].getValue();
		let leftColumn = "";

		const series = _getSeries(view, data, dataSeries, {legend: legendRange || ""});

		if(series.length)
			leftColumn = series[0].range;

		return leftColumn;
	}

	function getTopRow(){
		const data = _getDataParts(elements.range.getValue());
		const dir = elements.dataSeries.getValue() == "rows";
		const xAxisRange = _getDataParts(elements["xAxis.range"].getValue());
		let topRow = [];

		data.forEach(subdata => {
			if(xAxisRange.indexOf(subdata) == -1){
				const part = range(subdata, view);
				if(part){
					const range = dir ?
						toRange(part[0], part[1], part[2], part[1], part[4]) :
						toRange(part[0], part[1], part[0], part[3], part[4]);

					if(data.indexOf(range) == -1)
						topRow.push(range);
				}
			}
		});

		return topRow.join(",");
	}

	function updateRange(val, oldVal){
		let data = elements.range.getValue();

		const legend = elements["legend.range"].getValue();
		const xAxis = elements["xAxis.range"].getValue();
		const dataSeries = elements.dataSeries.getValue();

		const series = _getSeries(view, data, dataSeries, {legend, xAxis});

		oldVal = _getDataParts(oldVal);

		data = _getDataParts(data).filter(val => oldVal.indexOf(val) == -1);

		if(val){
			val = _getDataParts(val);
			val.forEach(range => {
				const exist = series.filter(obj => obj.range == range);
				if(!exist.length)
					data.push(range);
			});
		}

		setSilentValue(elements.range, data.join(","));
	}

	function updateAlign(val){
		const align = elements["legend.align"];
		const list = align.getList();

		if(val == "middle"){
			if(list.exists("center"))
				list.remove("center");
		}
		else if(!list.exists("center"))
			list.add({id:"center", value:labels["chart-legend-center"]}, 1);
	}

	function updateValign(val){
		const align = elements["legend.valign"];
		const list = align.getList();

		if(val == "center"){
			if(list.exists("middle"))
				list.remove("middle");
		}
		else if(!list.exists("middle"))
			list.add({id:"middle", value:labels["chart-legend-middle"]}, 1);
	}

	function updateType(val){
		const tabs = view.$$("chartTabs").getTabbar();
		switchRowsCols();
		handlePieLegend();

		elements.series.hideMarkers(val == "radar" || val == "line" || val == "spline");

		if(val == "area" || val == "bar") elements.stacked.show();
		else hideField(elements.stacked);

		if(val == "radar")
			elements["scale.circle"].show();
		else
			hideField(elements["scale.circle"]);

		if(val == "pie")
			elements["3D"].show();
		else
			hideField(elements["3D"]);

		if(_isPie(val)){
			hideBatch("axis");
			elements.pieInnerText.show();
			tabs.hideOption("advanced");
		}
		else{
			hideBatch("axis", true);
			hideField(elements.pieInnerText);
			tabs.showOption("advanced");
		}

		if(_isPie(val) || val == "radar")
			hideBatch("axisTitle");
		else
			hideBatch("axisTitle", true);
	}

	function updateSeries(oldXaxis){
		let data = elements.range.getValue();

		let series = elements.series.getValue();
		const legend = elements["legend.range"].getValue();
		const xAxis = elements["xAxis.range"].getValue();
		const dataSeries = elements.dataSeries.getValue();

		series = _getSeries(view, data, dataSeries, {series, legend, xAxis}, oldXaxis);

		elements.series.setValue(series);
	}

	function switchRowsCols(){
		const dir = elements.dataSeries.getValue() == "rows";
		const pie = _isPie(elements.type.getValue());
		if((!pie && dir) || (!dir && pie)){
			elements["legend.fromData"].define("label", labels["chart-col-legend"]);
			elements["xAxis.fromData"].define("label", labels["chart-row-xAxis"]);
		}
		else{
			elements["legend.fromData"].define("label", labels["chart-row-legend"]);
			elements["xAxis.fromData"].define("label", labels["chart-col-xAxis"]);
		}
		elements["legend.fromData"].refresh();
		elements["xAxis.fromData"].refresh();
	}

	function hideField(field){
		if(field.setValue)
			setSilentValue(field);
		field.hide();
	}

	function hideBatch(batch, visible){
		form.queryView({batch:batch}, "all").forEach(field => {
			if(visible)
				field.show();
			else
				hideField(field);
		});
	}

	function inValues(values){
		if(/stacked|3D/.test(values.type))
			values.type = values.type.replace(/stacked|3D/, "").toLowerCase();
		return values;
	}

	function outValues(values){
		if(values.stacked)
			values.type = "stacked"+values.type.charAt(0).toUpperCase()+values.type.substr(1);
		else if(values["3D"])
			values.type += "3D";

		return values;
	}
}

function closeEditor(view){
	const chartEditor = view.$$("chartEditor");
	view.callEvent("onUIEditStop", [chartEditor.viewId, "chart"]);
	chartEditor.viewId = null;
	view.$handleSelection = null;
	chartEditor.hide();
}

function addRichselect(name, label, data){
	return { view: "richselect", name:name, label:labels[label], suggest:{
		view: "ssheet-form-suggest",
		data: data
	}};
}

function getTypes(){
	return [
		{id:"line", value:labels["line-chart"]},
		{id:"spline", value:labels["spline-chart"]},
		{id:"splineArea", value:labels["splinearea-chart"]},
		{id:"area", value:labels["area-chart"]},
		{id:"bar", value:labels["bar-chart"]},
		{id:"radar", value:labels["radar-chart"]},
		{id:"pie", value:labels["pie-chart"]},
		{id:"donut", value:labels["donut-chart"]}
	];
}

function getLegendAlign(){
	return [
		{id:"left", value:labels["chart-legend-left"]},
		{id:"center", value:labels["chart-legend-center"]},
		{id:"right", value:labels["chart-legend-right"]}
	];
}

function getLegendValign(){
	return [
		{id:"top", value:labels["chart-legend-top"]},
		{id:"middle", value:labels["chart-legend-middle"]},
		{id:"bottom", value:labels["chart-legend-bottom"]}
	];
}