import "../views/formlate";

export class Dialog{
	constructor(view){
		this.view = view;
	}
	$init(){}
	$show(){}
	$hide(){}
	open(){
		if(!this.$dialog){
			this.$dialog = webix.ui(this.$init());
			this.view._destroy_with_me.push(this.$dialog);
			this.$dialog.attachEvent("onHide", ()=> {
				this.$hide();
				webix.UIManager.setFocus(this.view);
			});
			this.$dialog.attachEvent("onShow", ()=> {
				webix.UIManager.setFocus(this.$dialog);
			});
		}

		var form = this.$dialog.getBody();
		this.$dialog.show();
		if (this.$show(this.$dialog, form) === false)
			this.close();
	}
	close(){
		this.$dialog.hide();
	}
}