import {Dialog} from "./common";
import {range} from "../helpers/column_names";
import group from "../operations/undo_group";

export const action = "add-dropdown";

export class DialogBox extends Dialog{
	$show(){
		let form = this.$dialog.$$("form");
		this.cell = this.view.getSelectedId(true);
		if (!this.cell.length)
			return false;

		form.clear();

		//restore previosly selected range
		let value = this.view.getCellEditor(this.cell[0].row, this.cell[0].column);
		if (value && value.options)
			form.elements.range.setValue(value.options);

		this.view.$handleSelection = function(st, end, a, b){
			form.elements.range.setValue(a+":"+b);
			return false;
		};

		form.elements.range.focus();
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	$init(){
		return {
			view: "ssheet-dialog",
			position:"center",
			head:webix.i18n.spreadsheet.labels["dropdown-title"],
			move: true,
			body:{
				padding:0, view:"form", id:"form", rows:[
					{ view:"text", label:webix.i18n.spreadsheet.labels["dropdown-range"], name:"range" }
				]
			},
			on:{
				onSaveClick: () => this.okClick(),
				onCancelClick: () => this.close()
			}
		};
	}
	okClick(){
		var values = this.$dialog.$$("form").elements.range.getValue();

		if (values){
			if(!range(values, this.view))
				this.view.alert({text:webix.i18n.spreadsheet.labels["error-range"]});
			else{
				group.set(function(){
					for (var i = 0; i < this.cell.length; i++)
						this.view.setCellEditor(this.cell[i].row, this.cell[i].column, { editor:"ss_richselect", options:values});
				}, this);
				this.close();
			}
		}
		else
			this.close();
	}
}